import styled from 'styled-components'

export const Card = styled.div`
  cursor: pointer;
  display: flex;

  picture {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: -webkit-fill-available;
    overflow-x: hidden;

    img {
      width: 100%;
    }
  }
`
