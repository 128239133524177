import Banner from 'assets/img/communication-banners/banner-novidade-no-ranking-77.png'
import { communicationsTracker } from 'services/tracker/events/communications/trackers'
import { useAmplitude } from 'hooks/useAmplitude'
import { Link } from 'react-router-dom'
import * as S from './styles'

export function CommunicationBanner() {
  const { logEvent } = useAmplitude()

  const LINK_TO_REDIRECT = '/rank-77'

  const handleLogInteraction = () => {
    logEvent(communicationsTracker.eventName, {
      origin: location.pathname,
      action: communicationsTracker.actions.type,
      description: communicationsTracker.actions.description,
    })
  }

  return (
    <Link onClick={handleLogInteraction} to={LINK_TO_REDIRECT} rel="noreferrer">
      <S.Card>
        <picture>
          <source srcSet={Banner} media="(max-width: 640px)" />
          <img
            src={Banner}
            alt="Novidade no ranking 77. Ganhe 2x mais pontos."
          />
        </picture>
      </S.Card>
    </Link>
  )
}
